import { Card, CardContent, CardMedia, Grid, Typography, useTheme } from '@material-ui/core';
import React from 'react';
import Header from '../../components/header';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import UpdatedInfo from '../../components/templates/UpdatedInfo';
import { getApiUrl } from '../../utils/functions';
import { useGlobalStyles } from '../../utils/styles';

interface IInforme {
  image: string,
  name: string,
  to: string
}

const Informes = () => {
  const theme = useTheme();
  const globalClasses = useGlobalStyles();
  const updatedAt = '30 de Mayo de 2024.';

  const informes: IInforme[] = [
    {
      image: `${getApiUrl('portal')}/static/images/informes/informe-2023.png`,
      name: 'Informe de actividades 2023',
      to: `${getApiUrl('portal')}/static/docs/informes/InformeDeLabores2023.pdf`
    },     
    {
      image: `${getApiUrl('portal')}/static/images/informes/informe-2022.png`,
      name: 'Informe de actividades 2022',
      to: `${getApiUrl('portal')}/static/docs/informes/InformeDeLabores2022.pdf`
    },      
    {
      image: `${getApiUrl('portal')}/static/images/informes/informe-2021.png`,
      name: 'Informe de actividades 2021',
      to: `${getApiUrl('portal')}/static/docs/informes/InformeDeLabores2021.pdf`
    },
    {
      image: `${getApiUrl('portal')}/static/images/informes/fe-erratas-informe-2020.png`,
      name: 'Fe de erratas del informe 2020',
      to: `${getApiUrl('portal')}/static/docs/informes/FeErratasInforme2020.pdf`
    },
    
    {
      image: `${getApiUrl('portal')}/static/images/informes/informe-2020.png`,
      name: 'Informe de actividades 2020',
      to: `${getApiUrl('portal')}/static/docs/informes/InformeDeLabores2020.pdf`
    },
    {
      image: `${getApiUrl('portal')}/static/images/informes/informe-2019.png`,
      name: 'Informe de actividades 2019',
      to: `${getApiUrl('portal')}/static/docs/informes/InformeDeLabores2019.pdf`
    }

  ];

  return (
    <Layout>
      <SEO title='Informes de labores' />

      <div className={globalClasses.container}>
        <Header title='Informes de labores' />

        <div className={globalClasses.content}>
          <Typography>Aquí podrás ver y descargar los informes anuales de labores expedidos por el Instituto de Seguridad Social del Estado de Tabasco.</Typography>

          <Grid container spacing={3} style={{ marginTop: theme.spacing(3) }}>
            {informes.map((informe, index) =>
              <Grid key={index} item xs={12} sm={4} md={2}>
                <a href={informe.to} target="_blank" rel='noopener noreferrer' style={{ textDecoration: 'none' }}>
                  <Card>
                    <CardMedia
                      title={informe.name}
                      image={informe.image}
                      style={{
                        height: 245,
                        maxHeight: 245
                      }}
                    />
                    <CardContent style={{ paddingBottom: 16 }}>
                      <Typography color='primary'>{informe.name}</Typography>
                    </CardContent>
                  </Card>
                </a>
              </Grid>
            )}
          </Grid>
        </div>

        <UpdatedInfo updatedAt={updatedAt} />
      </div>
    </Layout>
  );
};

export default Informes;