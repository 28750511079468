import React, { FunctionComponent } from 'react';
import PropTypes from "prop-types";
import { makeStyles, Theme, createStyles, Typography } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => createStyles({
  date: {
    marginTop: theme.spacing(4),
    fontStyle: 'italic',
    '& span': {
      color: theme.palette.primary.main,
      fontWeight: 'bold'
    }
  }
}));

const UpdatedInfo = ({ fuente, updatedAt }) => {
  const classes = useStyles();

  return (
    <div className={classes.date}>
      {fuente ? <Typography><span>Fuente:</span> {fuente}.</Typography> : null}
      {updatedAt ? <Typography><span>Fecha de actualización:</span> {updatedAt}.</Typography> : null}
    </div>
  );
};

UpdatedInfo.propTypes = {
  fuente: PropTypes.string,
  updatedAt: PropTypes.string.isRequired
};

export default UpdatedInfo;